.modal {
    max-width: 800px;
}
.modalBody{
    width: 690px;
    height: 200px;
    position: relative;
    .modalActions{
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        div{
            margin-left: 25px;
        }
    }
}

@media only screen and (min-width: 801px) {
    .modal {
        max-width: 1200px;
    }
} 