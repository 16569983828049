.modalBody{
    width: 690px;
    height: 200px;
    position: relative;
    .title {
        text-align: center;
        padding: 15px 0;
        margin-bottom: 20px;
    }
    .description {
        text-align: center;
    }
    .modalActions{
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        div{
            margin-left: 25px;
        }
        .button {
            margin: 10px;
            width: 100px;
            height: 40px;
            cursor: pointer;
            text-align: center;
            border-radius: 6px;
            line-height: 37px;
            font-weight: 500;
            color: #FFFFFF;
            font-family:Gotham-Medium;
            background-color: #35b7fc;
            border: #35b7fc solid 1px;        
            margin-bottom: 25px;
            transition: 0.2s;
            outline: none;
            padding: 0;                    
        }
    
        .button:hover {
            background-color: #FFFFFF;
            color: #35b7fc;
            border: #35b7fc solid 1px;        
        }

        .buttonConfirm {
            background-color: #db2828;
            border: #db2828 solid 1px !important;  
        }

        .buttonConfirm:hover {
            background-color:white;
            color:#db2828;
        }
    }
}