.flexbox {
    display:flex;
    justify-content: center;
    margin-top: 20px;
}

.container {   
    max-width: 380px; 
    padding: 10px;
    flex: 1;
    border: 2px solid rgba(171, 171, 171, 0.2);
    border-radius: 6px;
    //min-height: 455px;
    min-height: 380px;
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        >div {
            padding-bottom: 10px;
        }
        .title {
            font-size: 20px;
            font-weight: 900;
        }
        .description{
            text-align: center;
        }
        .price {
            font-size: 26px;
            color:#35b7fc;
            margin-right: 4px;
        }

        button {
            margin: 10px;
            width: 150px;
            height: 50px;
            cursor: pointer;
            text-align: center;
            border-radius: 6px;
            line-height: 50px;
            font-weight: 500;
            color: #FFFFFF;
            font-family:Gotham-Medium;
            background-color: #35b7fc;
            border: #35b7fc solid 1px;        
            margin-bottom: 25px;
            transition: 0.2s;
            outline: none;
            padding: 0;        
            padding-bottom: 10px;
        }

        button:hover {
            background-color: #FFFFFF;
            color: #35b7fc;
            border: #35b7fc solid 1px;        
        }

        button:disabled {
            background-color: #ececef;
            color: black;
            border: #0000004a solid 1px;
        }
    }

    .footer {
        padding-left: 20px;
        padding-bottom: 40px;
        .icon {
            color: #35b7fc;
            padding-right: 10px;
        }
        >div {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
        }
    }

}