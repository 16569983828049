.title {
    text-align: center;
    padding: 30px 0;
}

.editKeycodeBlock{
    width: 40vw;
    margin: 20px;
    .dataInput {
        margin-left: 15px;
        margin-right: 15px;
    }

    .label {
        text-transform: uppercase;
        font-weight: 600;
        color: #35b7fc !important;
        font-family: Gotham-Book !important;
        font-size: 11.5px;
    }

    .datePickerContainer {
        display: flex;        
    }

    .datePicker {
        margin-left: 15px;
        width: 40%;
        flex-grow: 1;
    }

    .saveButton {
        margin: 10px auto;
        margin-top: 40px;
        width: 150px;
        height: 50px;
        cursor: pointer;
        text-align: center;
        border-radius: 6px;
        line-height: 50px;
        font-weight: 500;
        color: #FFFFFF;
        font-family:Gotham-Medium;
        background-color: #35b7fc;
        border: #35b7fc solid 1px;        
        margin-bottom: 25px;
        transition: 0.2s;
        outline: none;
        padding: 0;        
        padding-bottom: 10px;
    }

    .saveButton:hover {
        background-color: #FFFFFF;
        color: #35b7fc;
        border: #35b7fc solid 1px;        
    }

    .selectPlan {
        div[role="listbox"] {
            border: 1px solid rgb(158, 158, 158);
        }        
    }

    .dashedPlan {
        div[role="listbox"] {
            border: 2px dashed red !important;
            box-shadow: none !important;
        }
    }

    .dashed {       
        input {
            border-bottom: 2px dashed red !important;
            box-shadow: none !important;
        }                        
    }
}

:global {
    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        top: 51px;
        width: 280px;
        border: 1px solid #aaa;
        background-color: #fff;
        font-family: Helvetica, sans-serif;
        font-weight: 300;
        font-size: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
        max-height: 140px;
        overflow-y:scroll;
    }
    
    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }
    
    .react-autosuggest__suggestion--highlighted {
        background-color: #ddd;
    }

    .react-datepicker-wrapper {
        width: 100%
    }

    .react-datepicker__input-container {
        width: 100%;
        input {
            text-align: left;
        }
    }

    div[role="listbox"] {
        width: 100%;
    }
}
