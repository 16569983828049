.button {
    background-color: #039be5;
}
.logInWrapper{
    height: 320px;
    position: absolute;
    background-color: #FFFFFF;
    border: 5px solid #039be5;
    border-radius: 10px;
    z-index: 5;;
    top: 100px;
    right: 12%;
    left: 12%;
    box-shadow: 30px 30px 30px #CDCDCD;
    padding-top: 40px;
    div{
        h1{
            font-family: 'Gotham-Book';
            font-size: 22px;
        }
    }
    .close{
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
    }
    .buttons{
        span{
            cursor: pointer;
            font-family: Gotham-Book;
            font-weight: 700;
            color:  #039be5;
        }
        div{
            display: flex;
            justify-content: flex-end;
            div{
                margin: 0 20px;
            }
        }
    }
}