.paymentHistoryPage{
    padding-top: 30px;
    
    .paymentHistoryContainer {
        width: 100%;
        text-align: center;
    }
    
    .paymentsPagination {
        justify-content: center;
        margin-top: 15px;
    }
    :global {
        .ui.inline.loader.active {
            margin-left: -8px;
        }
      li.active {
          background-color: #35b7fc;
      }
    }

    .paymentHistoryTable{
        
        border-bottom: 2px solid rgba(171, 171, 171, 0.2);
        text-align: center;
        
        tr:nth-child(1) {            
            border-bottom: 2px solid rgba(171, 171, 171, 0.2);
            border-left: none;
            border-right: none;       
            font-size: larger;     
        }
        tr:nth-child(1):hover {
            background-color: white;
        }        

        th {
            border-top: none;
            border-right: none;
            border-left: none;
            width: 15%;
        }
        
        tr {
            border-left: 2px solid rgba(171, 171, 171, 0.2);
            border-right: 2px solid rgba(171, 171, 171, 0.2);
            text-align: center;
        }

        td {
            font-family: Gotham-Book;
            font-weight: 400;
            font-size: small;
            width: 15%;
            color: #404040;      
            text-align: center;          
        }        

        td:nth-child(2), th:nth-child(2) {
            width: 25%
        }

        td:nth-child(5) {
            text-transform: capitalize;
        }
        
        td:last-child {
            width: 100%;
        }

        td:last-child div {
            display: inline-flex;
            padding: 0px 5px;
        }
        
        .break {
            word-break: break-word;
        }
    }    
}
