.title {
    text-align: center;
    padding: 30px 0;
}

.editUserBlock{
    width: 100%;
    width: 40vw;
    margin: 20px;

    .phoneContainer {
        margin-bottom: 30px;
    }

    .actionsContainer {
        display: flex;
        justify-content: center;
    }

    .partner {
        display: flex;
        justify-content: flex-start;
        margin-left: 15px;
        p {
            margin-top: 2px;
        }
        :global(.col) {
            flex-grow: 0;
            width: 60px;
        }
    }

    .saveButton {
        margin: 10px auto;        
        width: 150px;
        height: 50px;
        cursor: pointer;
        text-align: center;
        border-radius: 6px;
        line-height: 50px;
        font-weight: 500;
        color: #FFFFFF;
        font-family:Gotham-Medium;
        background-color: #35b7fc;
        border: #35b7fc solid 1px;        
        margin-bottom: 25px;
        transition: 0.2s;
        outline: none;
        padding: 0;        
        padding-bottom: 10px;
    }

    .saveButton:hover {
        background-color: #FFFFFF;
        color: #35b7fc;
        border: #35b7fc solid 1px;        
    }

    .dashed {       
        input {
            border-bottom: 2px dashed red !important;
            box-shadow: none !important;
        }                        
    }

    .error{
        color: red;
        padding: 0.75rem 0.75rem;
    }
}
.errorField{
    input{
        border-bottom: 2px dashed #cf0000 !important;
        outline: none;
    }
    input:focus{
        border-bottom: 2px dashed #cf0000 !important;
        outline: none;
    }
}