.assemblyGuide {
    iframe {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    figure {
        margin: 0 !important;
    }
    a {
        font-weight: bold !important;
        text-decoration: underline;
    }
}