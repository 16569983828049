.sharedAccountsItem {
    .actions {
        div {
            border-radius: 6px;
            transition: 0.2s;      
            margin-right: 5px;
            color: white;
            cursor: pointer;
            width: 45px;
        }
        div.delete {
            background-color: #db2828;
            border: 1px solid #db2828;
            margin-left: auto;
            margin-right: auto;
        }  
        div.delete:hover {
            background-color: white;
            color:#db2828;            
        }  
    }
}