.signup_wrapper{
    padding-top: 30px;
    margin-bottom: -20px;
    .login{
        display: inline-block;
        color: #35b7fc;
        font-family: "Gotham-Book";
        font-weight: 500;
        margin-left: 10px;
        cursor: pointer;
    }
    .active{
        font-weight: 600 !important;
    } 
}
input:focus{
    border-bottom: 1px solid #35b7fc !important;
    box-shadow: 0 1px 0 0 #35b7fc !important;    
}

input:focus::placeholder {
    color: #35b7fc !important;
}

.errorField{
    input{
        border-bottom: 2px dashed #cf0000 !important;
        outline: none;
        box-shadow: none !important;
    }
    input:focus{
        border-bottom: 2px dashed #cf0000 !important;
        outline: none;
        box-shadow: none !important;
    }
}

.signup_wrapper:before{
    content: '';
    width: 220px;
    height: 310px;
    border-right: 2px solid #35b7fc;
    border-top: 2px solid #35b7fc;
    position: absolute;
    bottom:0;
    left:0;
    border-top-right-radius: 20px;
}
.topSquare{
    width: 135px;
    height: 295px;
    border-left: 2px solid #35b7fc;
    border-bottom: 2px solid #35b7fc;
    position: absolute;
    top:0;
    right:0;
    border-bottom-left-radius: 20px;
}
.topSquare:before{
    content: '';
    width: 0px;
    height: 426px;
    border-left: 2px solid #35b7fc;
    position: absolute;
    top:0;
    left:66px;
}
.title{
    margin-top: 270px;
    font-size: 22px;
    font-weight: bold;
}
.get_started_block{
    .blueBtn{
        background-color: #35b7fc;
        width: 300px;
        height: 95px;
        line-height: 95px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: bold;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        display: inline-block;
        transition: 0.2s;
        border: #35b7fc 1px solid;
    }

    .blueBtn:hover{
        color: #35b7fc;
        background-color: white;
    }
    margin-top: 40px;
    margin-bottom: 25px;
    p{
        margin-left: 50px;
        display: inline;
    }
}

.error{
    color: red;
    padding-left: 0.75rem;
    margin-bottom: 14px !important;
}


@media only screen and (min-width: 1600px) {
    .signup_wrapper{
        padding-top: 30px;
        margin-bottom: -20px;
        .login{
            font-weight: 500;
            margin-left: 10px;
        }
    }
    .signup_wrapper:before{
        content: '';
        width: 220px;
        height: 310px;
        bottom:0;
        left:0;
    }
    .topSquare{
        width: 135px;
        height: 295px;
        position: absolute;
        top:0;
        right:0;
    }
    .topSquare:before{
        width: 0px;
        height: 426px;
        top:0;
        left:66px;
    }
    .title{
        margin-top: 270px;
        font-size: 22px;
    }
    .get_started_block{
        .blueBtn{
            width: 300px;
            height: 95px;
            line-height: 95px;
            font-size: 16px;
        }
        margin-top: 40px;
        margin-bottom: 25px;
        p{
            margin-left: 50px;
        }
    }
// ------------------------------------    
}
@media only screen and (max-width: 1600px) {
    .topSquare{
        width: 44px;
        height: 84px;
    }
    .topSquare:before{
        height: 133px;
        left: 21px;
    }
    .signup_wrapper:before{
        width: 65px;
        height: 70px;
    }
    .title{
        margin-top: 0px;
        font-size: 22px;
        font-weight: bold;
    }
    .get_started_block{
        .blueBtn{
            background-color: #35b7fc;
            width: 190px;
            height: 60px;
            line-height: 60px;
            font-size: 16px;
        }
    }
}


@media only screen and (max-width: 1260px) {
    .get_started_block{
        .blueBtn{
            background-color: #35b7fc;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            width: auto;
            padding-left: 20px;
            padding-right: 20px;
            margin-right: 10px;
        }
        p{
            margin: 0 auto;
        }

    }
}

@media only screen and (max-width: 920px) {
    .topSquare{
        width: 22px;
        height: 44px;
    }
    .topSquare:before{
        height: 60px;
        left: 13px;
    }
    .signup_wrapper:before{
        width: 32px;
        height: 25px;
    }
    .get_started_block{
        .blueBtn{
            background-color: #35b7fc;
            margin: 0 auto;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
        }
        p{
            margin: 0 auto;
            margin-left: 10px;
        }

    }
}