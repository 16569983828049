.docContainer{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 17px; 
    box-sizing: content-box;
        menu{
            margin: 0;
            position: fixed;
            top: 80px;
            left: 0;
            right: 0;
            bottom:0;
            height: 100%;
            overflow: hidden;
            border-left: 2px solid rgba(175, 180, 183, 0.2);
            background-color: #f5f7f9;
            :global(.ui.accordion:not(.styled) .title~.content:not(.ui)) {
                padding: 0px;
            }            
            :global(.ui.accordion){
                //position: fixed;
                margin-top: 80px;
                color: #35b7fc;
                :global(.title) {
                    padding: 0px;
                    margin-top: 15px;
                }
                a{
                    text-decoration: none;
                }        
                :global(div.active.content) {
                    border-left: 3px solid #35b7fc;
                    color: #35b7fc; 
                }
                :global(.active.content) .activeLink div {
                    color: #35b7fc;
                    text-transform: none;
                }
                :global(.active.title) div {
                    text-decoration: none;
                    border-left: 3px solid #35b7fc;
                    color: #35b7fc; 
                    padding-bottom: 0px;
                    text-transform: none;
                }            
                :global(.title) div, :global(.content) div{
                    font-family:Gotham-Book;
                    font-weight: 500;
                    margin-top: 0px;
                    padding-left: 30px;
                    font-weight: 500;
                    color: #404040;
                    text-transform: none;
                } 
                :global(.content) div {
                    padding-left: 50px;
                    padding-top: 10px;
                }  
            }        
        }
        .dockBlock{
            padding: 20px 40px 20px 40px;
            .oneDocItem{
                font-family:Gotham-Book;
                font-size: 16px;
                font-weight: 400;
                margin-right: 60px;
                .docTitle{
                    font-family: DINNextLTPro-Regular;
                }
                .tableName{
                    text-transform: uppercase;
                    color: #35b7fc;
                    font-family: Gotham-Medium;
                    font-weight: 700;
                }
                p, a, i, b, em{
                    font-family: Gotham-Book;
                    font-weight: 400;
                }       
                b, b i {
                    font-weight: bold;
                }
                em {
                    font-style: normal;
                    background-color: #ececef;
                    padding: 3px;
                    margin-left: 1px;
                    margin-right: 1px;
                    margin-top: 2px;
                }       
                figure {
                    margin-top: 40px;
                    margin-bottom: 40px;
                    div {
                        white-space: pre-line;
                        background: #ececef;
                        padding:20px;
                    }
                    img {                                    
                        max-width: 600px;                        
                        max-height: 400px;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 5px;
                        display: block;
                    }
                    figcaption {
                        text-align: center;
                        margin:auto;
                        max-width: 600px;
                    }
                    :global(.prettyprint) {
                        clear:both;
                    }
                    iframe {
                        border: 3px solid black;
                        padding: 10px;
                        margin-left: auto;
                        margin-right: auto;
                        display:block;
                        max-width: 656px;
                        max-height: 456px;
                        height: 456px;
                        width: 100%;
                        
                    }
                }
            }
            ul.list > li {
                margin-top: 20px;
                list-style-type: initial;
                margin-left: 30px;
            }
        }
        .parallel{
            display: block;
            float: left;
            padding: 0px;            
            margin-right: 100px;            
            p {
                margin-top: 40px;
                padding: 0px;
            }
        }
}

// =====================================================================================================
@media only screen and (max-width: 1600px) {
    .docContainer{
        menu{
                top: 45px;                
                :global(.accordion){
                    margin-top: 0px;
                    :global(.title){
                        padding-left: 25px;
                    }   
                }        
            }
            .dockBlock{
                padding: 0px 40px 20px 20px;
                .oneDocItem{
                    margin: 40px;
                    .docTitle{
                        font-size: 31px;
                    }
                }              
            }
    }
}
@media only screen and (min-width: 760px) {
    menu{
        padding-top: 40px;
        /*li{
            width: 150px;
        }*/
    }
    .subMenu{
        display: none;
    }
}    
@media only screen and (max-width: 768px) {
    .docContainer{
        .subMenu{
            display: inline-block;
            height: 30px;
            padding: 3px 5px 3px 5px;
            background-color: #35b7fc;
            transform: rotate(90deg);
            position: fixed;
            top: 190px;
            white-space: nowrap;
            z-index: 90;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            color: #ffffff;
            border: 1px solid #CDCDCD;
            cursor: pointer;
            transition: all 1s;
        } 
        .subMenu:hover{
            background-color: #1482bc;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }  
            menu{
                z-index: 90;
                top: 45px;
                width: 195px;
                padding-top: 1px; 
            }
            .dockBlock{
                padding: 0px 40px 20px 45px;
                .oneDocItem{
                    margin-right: 60px;
                }
                figure{
                    margin-left: 0px;
                    margin-right: 0px;
                    iframe {                        
                        height: 200px !important;                        
                    }
                }
            }
    }
}

@media only screen and (max-width: 576px) {
    figure {
        img {
            width: 100%
        }
    }
}