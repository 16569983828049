@tailwind base;
@tailwind components;
@tailwind utilities;


h1 {
    @apply text-4xl mb-8;
}

h2 {
    @apply text-3xl mb-8;
}

h3 {
    @apply text-2xl mb-8;
}

h4 {
    @apply text-xl;
}

p {
    @apply mb-2
}
