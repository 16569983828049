footer{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    color: #98a6b0;
    font-family: "Gotham-Book";
    font-weight: 400;
    ul{
        margin-left: 30px;
        display: inline-block;
        li{
            margin-right: 20px;
            display: inline-block;
            a{
                color: #35b7fc;
                cursor: pointer;
            }
        }
    }
}



@media only screen and (max-width: 1200px) {
    footer{
        width: 100%;
        text-align: center;
        ul{
            margin-left: 10px;
            li{
                margin-right: 10px;
            }
        }
    }
}

@media only screen and (max-width: 920px) {

}