.samplePage{
    .title{
        font-family:  DINNextLTPro-Regular !important;
        font-weight: 800 !important;
    }
    .one_app_item{
        display: inline-block;
        .one_app_item_logo{
            width: 150px;
            height: 150px;
            box-shadow: 0 5px 15px 1px rgba(0, 0, 0, 0.17);
            border-radius: 10px;
            background-color: #35b7fc;
            margin-bottom: 27px;
            position: relative;
            img{
                width: 50px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        span{
            font-weight: 900;
            color: #35b7fc;
            font-size: 18px;
        }
        p{
            font-size: 14px;
            color: #404040; 
        }
    }
}

@media only screen and (max-width: 768px) {
    .one_app_item{
        display: inline-block;
        .one_app_item_logo{
            width: 120px;
            height: 120px;
            margin-bottom: 27px;
            img{
                width: 50px;
                position: absolute;
                top: 50%;
                left: 50%;
            }
        }
        span{
            font-size: 18px;
        }
        p{
            font-size: 14px;
        }
    }
}