.container {
    padding-top: 20px;
    .title {

    }
}
:global{
    .container-fluid{
        padding-right: 50px;
        padding-left: 50px;
    }
}

@media only screen and (max-width: 576px) { 
    .title {
        text-align: center;
    }
}