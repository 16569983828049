.getStartedTile {
    min-height: 313px;
}

.headerBox {
    height: 40px;
    display: flex;
    padding-bottom: 10px; 
    border-bottom: 2px solid #e1e5e8;
    margin-bottom: 15px;    
    .title{                
        padding-left: 20px;    
        flex: 2;         
    }
    .closeMsg {                          
        :global(.icon) {
            float: none;
            color: #35b7fc;
        }      
        :global(.icon):hover {            
            color: #007bff;
        }        
        
    }
}

.startedInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.startedInfoItem{
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 80px;
    .imgBox {
        flex: 1;
        text-align: center;
    }
    img{        
        max-height: 50px;
    }
    div{
        margin: auto 0;
        width: 50%;
        font-weight: 400;
        flex: 5;
        span, span a{
            font-family: Gotham-Book;
            font-size: 18px;
            color: #35b7fc;
            text-transform: none;
            font-weight: 400;            
        }
        p{
            font-size: 12px;
            font-family: "Gotham-Book";
        }
    }
    a{
        color: #35b7fc;
        font-family: Gotham-Book;
        text-decoration: none;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 12px;
        margin: auto 0;
        margin-right: 10px;
    }
}

.activationCount{
    border-bottom: 2px solid #e1e5e8;
    font-family: DINNextLTPro-Regular;
    font-weight: 300;
    span{
        font-weight: 400;
        color: #35b7fc;
        font-size: 36px;
    }
}

.activationCount+div{
    font-family: DINNextLTPro-Regular;
    font-weight: 300;
    span{
        font-weight: 400;
        color: #35b7fc;
        font-size: 36px;
    }
}

.activationSummary{
    padding: 15px;
    border-radius: 6px;
    background-color: #f3f5f8;
    p{
        font-family:Gotham-Book;
        font-weight: 400;
        strong{
            font-family:Gotham-Medium;
            font-weight: 500;
        }
    }
}

.blueBtn{
    margin: 10px;
    width: 150px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
    line-height: 50px;
    font-weight: 500;
    color: #FFFFFF;
    font-family:Gotham-Medium;
    font-weight: 500;
    background-color: #35b7fc;
}


@media only screen and (max-width: 920px) {
    .startedInfoItem{
        img{
            padding: 5px;
            max-width: 40px;
            max-height: 40px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    
}