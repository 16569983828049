.keycodesPage{
    padding-top: 30px;
    
    .editButton {      
      margin-bottom: 20px;
      width: 150px;
      height: 50px;
      cursor: pointer;
      text-align: center;
      border-radius: 6px;
      line-height: 50px;
      font-weight: 500;
      color: #FFFFFF;
      font-family:Gotham-Medium;
      font-weight: 500;
      background-color: #35b7fc;
      border: 1px solid #35b7fc;
      transition: 0.2s;      
      margin-right: auto;
    }

    .editButton:hover {
        color: #35b7fc;
        background-color: white;
    }

    .keycodeContainer {
        margin-left: 8.3%;
        margin-right: 8.3%;
        width: 100%
    }

    .keycodeTable{
        
        border-bottom: 2px solid rgba(171, 171, 171, 0.2);
        text-align: center;
        
        tr:nth-child(1) {            
            border-bottom: 2px solid rgba(171, 171, 171, 0.2);
            border-left: none;
            border-right: none;       
            font-size: larger;     
        }
        tr:nth-child(1):hover {
            background-color: white;
        }        

        th {
            border-top: none;
            border-right: none;
            border-left: none;
            width: 15%;
        }
        
        tr {
            border-left: 2px solid rgba(171, 171, 171, 0.2);
            border-right: 2px solid rgba(171, 171, 171, 0.2);
            text-align: center;
        }

        td {
            font-family: Gotham-Book;
            font-weight: 400;
            font-size: small;
            width: 15%;
            color: #404040;      
            text-align: center;          
        }        

        td:nth-child(2), th:nth-child(2) {
            width: 25%
        }

        td:nth-child(5) {
            text-transform: capitalize;
        }
        
        td:last-child {
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }

        td:last-child div {
            flex-grow: 1;
        }
        
        .break {
            word-break: break-word;
        }
    }    
}
