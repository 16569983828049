@font-face{
    font-family:Gotham-BlackIta;
    src: url(../fonts/Gotham/OpenType/Gotham-BlackIta.otf);
}
@font-face{
    font-family:Gotham-Bold;   
    src: url(../fonts/Gotham/OpenType/Gotham-Bold.otf);
}
@font-face{
    font-family:Gotham-BoldIta;
    src: url(../fonts/Gotham/OpenType/Gotham-BoldIta.otf);
}
@font-face{
    font-family:Gotham-Book;
    src: url(../fonts/Gotham/OpenType/Gotham-Book.otf);
}
@font-face{
    font-family:Gotham-BookIta;
    src: url(../fonts/Gotham/OpenType/Gotham-BookIta.otf);
}
@font-face{
    font-family:Gotham-Light;
    src: url(../fonts/Gotham/OpenType/Gotham-Light.otf);
}
@font-face{
    font-family:Gotham-LightIta;
    src: url(../fonts/Gotham/OpenType/Gotham-LightIta.otf);
}
@font-face{
    font-family:Gotham-Medium;
    src: url(../fonts/Gotham/OpenType/Gotham-Medium.otf);
}
@font-face{
    font-family:Gotham-MediumIta;
    src: url(../fonts/Gotham/OpenType/Gotham-MediumIta.otf);
}
@font-face{
    font-family:Gotham-Thin;
    src: url(../fonts/Gotham/OpenType/Gotham-Thin.otf);
}
@font-face{
    font-family:Gotham-ThinIta;
    src: url(../fonts/Gotham/OpenType/Gotham-ThinIta.otf);
}
@font-face{
    font-family:Gotham-Ultra;    
    src: url(../fonts/Gotham/OpenType/Gotham-Ultra.otf);
}
@font-face{
    font-family:Gotham-UltraIta;
    src: url(../fonts/Gotham/OpenType/Gotham-UltraIta.otf);
}
@font-face{
    font-family:Gotham-XLight;
    src: url(../fonts/Gotham/OpenType/Gotham-XLight.otf);
}
@font-face{
    font-family:Gotham-XLightIta;
    src: url(../fonts/Gotham/OpenType/Gotham-XLightIta.otf);
}
@font-face{
    font-family:GothamCond-Bold;
    src: url(../fonts/Gotham/OpenType/GothamCond-Bold.otf);
}
@font-face{
    font-family:GothamCond-Book;
    src: url(../fonts/Gotham/OpenType/GothamCond-Book.otf);
}
@font-face{
    font-family:GothamCond-Light;
    src: url(../fonts/Gotham/OpenType/GothamCond-Light.otf);
}
@font-face{
    font-family:GothamCond-Medium;
    src: url(../fonts/Gotham/OpenType/GothamCond-Medium.otf);
}

/* --------------------------------DINNextLTPro----------------------------------- */


@font-face{
    font-family:DINNextLTPro-Bold;
    src: url(../fonts/DINNext/DINNextLTPro-Bold.otf);
}
@font-face{
    font-family:DINNextLTPro-Condensed;
    src: url(../fonts/DINNext/DINNextLTPro-Condensed.otf);
}
@font-face{
    font-family:DINNextLTPro-Light;
    src: url(../fonts/DINNext/DINNextLTPro-Light.otf);
}
@font-face{
    font-family:DINNextLTPro-LightCondensed;
    src: url(../fonts/DINNext/DINNextLTPro-LightCondensed.otf);
}
@font-face{
    font-family:DINNextLTPro-Medium;
    src: url(../fonts/DINNext/DINNextLTPro-Medium.otf);
}
@font-face{
    font-family:DINNextLTPro-Regular;
    src: url(../fonts/DINNext/DINNextLTPro-Regular.otf);
}