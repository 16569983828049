
.paymentHistoryItem {   
  div {
      border-radius: 6px;
      transition: 0.2s;
      margin-right: -0.5px;
      color: white;
      cursor: pointer;
  } 
  div.download {
      background-color: gold;
      border: 1px solid gold;
  }      
  div.download:hover {
      background-color: white;
      color: gold;            
  }    
  .upperCase {
      text-transform: uppercase;
  }  
  .currency::before {
    content: "$";
  }      
}

