.activationTile {
    min-height: 310px;
    text-align: center;

    :global {
        .statistics {
            display: block;
            text-align: center;
            margin-bottom: 15px;
            margin-top: 15px;
        }

        .ui.mini.statistics {
            margin-bottom: 0px;
        }

        .ui.statistics>.statistic {
            margin: 1em 1em;
        }

        .ui.statistics .label {
            font-size: 12px;     
        }

        .ui.statistics .value {
            text-transform: none;
        }

        .inverted.statistic .value, .inverted.statistic .label {
            background-color: white !important;
        }

        .inverted.statistic .label {
            color: black;
        }
    }                    
}

.title {
    display: flex;
    * {
        align-self: center;
    }
    :global(.middle-size-font) {
        margin-right: 5px;
        margin-bottom: 1px;
    }   
}

.manageDevice {
    text-align: center;
    color: #35b7fc;
    cursor: pointer;
    p {
        border: 1px solid #35b7fc;
        padding: 8px;
        border-radius: 6px;
        transition: 0.2s;
    }
    p:hover {
        background-color: #35b7fc;
        color: white;
    }
    
}

.headerBox {
    height: 40px;
    border-bottom: 2px solid #e1e5e8;
    padding-bottom: 10px; 
    padding-left: 20px;    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.keycode {        
    text-align: center;
    margin-top: 10px;
    word-break: break-word;
}

.keycode b {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.activationCount{
    border-bottom: 2px solid #e1e5e8;
    font-family: DINNextLTPro-Regular;
    font-weight: 300;
    span{
        font-weight: 400;
        color: #35b7fc;
        font-size: 36px;
    }
}

.activationCount+div{
    font-family: DINNextLTPro-Regular;
    font-weight: 300;
    span{
        font-weight: 400;
        color: #35b7fc;
        font-size: 36px;
    }
}

.activationSummary{
    padding: 15px;
    border-radius: 6px;
    background-color: #f3f5f8;
    p{
        font-family:Gotham-Book;
        font-weight: 400;
        strong{
            font-family:Gotham-Medium;
            font-weight: 500;
        }
    }
}

.blueBtn{
    margin: 10px;
    width: 150px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
    line-height: 50px;
    font-weight: 500;
    color: #FFFFFF;
    font-family:Gotham-Medium;
    font-weight: 500;
    background-color: #35b7fc;
    border: 1px solid #35b7fc;
    transition: 0.2s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.blueBtn:hover {
    color: #35b7fc;
    background-color: white;
}

.cancelModal {
    width: 50vw;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
    .title {
        align-self: center;
    }

    text {
        align-self: center;
        text-align: center;
    }

    .buttons {
        display: flex;
        align-self: center;

        .button {
            margin: 10px;
            width: 100px;
            height: 40px;
            cursor: pointer;
            text-align: center;
            border-radius: 6px;
            line-height: 37px;
            font-weight: 500;
            color: #FFFFFF;
            font-family: Gotham-Medium;
            background-color: #35b7fc;
            border: #35b7fc solid 1px;
            margin-bottom: 25px;
            transition: 0.2s;
            outline: none;
            padding: 0;
        }

        .button:hover {
            color: #35b7fc;
            background-color: white;
        }        

        .confirm {
            background-color: #db2828;
            border: #db2828 solid 1px;
        }

        .confirm:hover {
            background-color: white;
            color: #db2828;
        }


    }
}


