.downloadsPage{
    padding-top: 40px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 15px;

    .pageDescription{
        span{
            color: #35b7fc;
            cursor: pointer;
        }
    }
    .downloadsTables{
        border: 2px solid rgba(171, 171, 171, 0.2);
            td {
                font-family: Gotham-Book;
                font-weight: 400;
                width: 25%;
                color: #35b7fc;                
            }
            td:nth-child(2){
                color: #404040;
            }
            .lastLine{
                background-color: #ededed;
                span{
                    color: #35b7fc;
                    cursor: pointer;
                }
            }
            .break {
                word-break: break-word;
            }
    } 

    .releaseNotes {
        cursor: pointer;
    }

    .releaseNotes:hover {
        color: #007bff;
    }
}

.modalContent {
    width: 80vw;
    max-width: 740px;
    height: 500px;
}

@media only screen and (min-width: 1600px) {
    .downloadsPage{
        padding-top: 40px;
        .pageDescription{
            font-family: Gotham-Book;
            font-size: 26px;
        }
        .downloadsTables{
            border: 2px solid rgba(171, 171, 171, 0.2);
                td{
                    font-family: Gotham-Book;
                    font-weight: 400;
                    width: 25%;
                    color: #35b7fc;
                    
                }
        }
    }
    
}
@media only screen and (max-width: 1600px) {
    .downloadsPage{
        padding-top: 20px;
    }
    
}

@media only screen and (max-width: 1200px) {
    .downloadsPage{
        padding-top: 0px;
        overflow: hidden;
    }
}

@media only screen and (max-width: 920px) {    

}

@media only screen and (max-width: 576px) {        
    :global {
        .big-size-font {
            text-align: center;
        }
    }
}