.center {
    display: flex;
    justify-content: center;
}

.container {
    width: 200px;        
    margin-top: 20px;    
    >div {
        background-color: #35b7fc;
        height: 150px;
        width: 150px;
        margin: 10px;
        margin-left: 0px;
        display: flex;
        justify-content: center;
        img {
            align-self: center;
            height: 70px;
        }
    }
}

@media only screen and (max-width: 576px) {
    .container {
        margin-top: 30px;
        text-align: center;
        div {
            margin-left: auto;
            margin-right: auto;
        }
    }
}