.container {
    margin-left: -12px;
    .hide {
        display: none;
    }
    .spinner {
        margin: 100px 160px;
    }
    .title {
        text-align: center;
        padding: 30px 0;
    }
    .description {
        text-align: center;
    }
    .content {
        text-align: center;
        padding: 30px 0;
    }
    hr {
        border: none;
        border-left: 1px solid hsla(200, 10%, 50%,100);
        height: auto;
        width: 1px;
        margin: 0;
        margin-left: 40px;
        margin-right: 40px;
    }
    .summary {
        margin-left: 10px;
        margin-top: 10px;
    }
    .body {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        >div {
            padding-left: 40px;
            padding-right: 40px;
            >div {
                padding-bottom: 15px;
            }
        }
        ul {
            margin-bottom: 20px;
            li {
                width: 200px;                
                border: 2px solid rgba(171, 171, 171, 0.2);
                border-bottom: none;
                padding: 10px;
                cursor: pointer;
                &.active {
                    background-color: #35b7fc;
                    color: #FFFFFF;
                }
                &.inactive {
                    background-color: #FFFFFF;                    
                }
                &:first-child{
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
                &:last-child{
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                    border-bottom: 2px solid rgba(171, 171, 171, 0.2);                    
                }
                span {
                    font-size: 10px;
                    display: table;
                }
            }
        }
        ul.methods {
            max-height: 200px;
            overflow-y: auto;
            padding-right: 10px;
            li {
                i {
                    float: left;
                }
                .methodInfo {
                    float: left;
                    span {
                        clear: both;
                    }
                }
            }
        }
        .methodBrand {
            padding-top: 1px;
        }
        .methodNew {
            padding-top: 2px;
        }
        .methodBrandACH {
            padding-top: 3px;
        }
        .toggleMethods {
            color: #35b7fc;
            cursor: pointer; 
        }
        .toggleMethods:hover {
            color: #007bff;
        }
        .loaderContainer {
            display: flex;            
            justify-content: center;
            margin-top: 80px;
        }
        .subtitle {
            margin-top: 30px;
            margin-bottom: 30px;
        }           
    }
    .plaidButton button {
        margin-bottom: 3px !important; 
    }    
    .plaidButtonDisabled button {
        pointer-events: none;
        background: #ececef !important;
        color: black;
        border: solid 1px #0000004a !important;
        cursor: not-allowed !important;
        margin-bottom: 3px !important; 
    }

    .plaidButton p, .plaidButtonDisabled p {
        font-size: 12px;
    }
    //!important are needed because of external plaid button
    button {
        margin: 10px;
        width: 150px;
        height: 50px;
        cursor: pointer;
        text-align: center;
        border-radius: 6px !important;
        line-height: 50px;
        font-weight: 500;
        color: #FFFFFF;
        font-family:Gotham-Medium;
        background-color: #35b7fc !important;
        border: #35b7fc solid 1px !important;        
        margin-bottom: 25px;
        transition: 0.2s;
        outline: none;
        padding: 0 !important;        
        padding-bottom: 10px !important;
    }

    button:hover {
        background-color: #FFFFFF !important;
        color: #35b7fc !important;
        border: #35b7fc solid 1px !important;        
    }

    input[type="checkbox"]+label {
        padding: 0 !important;
        margin-left: -20px;
    }

    input:focus + label {
        color: inherit !important;
        padding-left: 0 !important;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        height: 100px;
        align-items: center;                
        span {
            cursor: pointer;
            color: #35b7fc;
            margin-bottom: 10px;
        }
        &.return {
            //justify-content: space-between;
        }
    }

    .flexCenter {
        justify-content: center;
    }

    .cardSecureData {
        display: flex;
        flex-wrap: wrap;
        * {
            flex: 1;
        }
    }

    .reviewContainer {
        display: flex;
        margin: 40px 80px 40px 80px;
        div div {
            margin: 15px 5px;
        }
    }

    .confirmationMessage {
        margin: 5px 10px;
        white-space: pre;
        text-align: center;
    }

    :global {
        .col {
            padding: 0;
        }

        input, .StripeElement {
            display: block;
            margin: 10px 10px 20px 10px;
            max-width: 500px;
            padding: 10px 0px;
            background: white;
            border-bottom: 1px solid #9e9e9e;
            min-width: 200px;
        }

        input::placeholder {
            color: #aab7c4;
        }

        input:focus, .StripeElement--focus {
            -webkit-transition: all 150ms ease;
            transition: all 150ms ease;
            border-bottom: 2px solid #35b7fc !important;
        }                

        .col.input-field.s12 label {
            left:0;
            padding-left: 0px; 
        }
    }

    .dashed {
        display: block;
        margin: 10px 10px 20px 10px;
        max-width: 500px;
        padding: 10px 0px;
        background: white;
        border-bottom: 2px dashed red !important;
        min-width: 200px;
    }
    
    .couponBox { 
        margin-top: 30px;
        margin-bottom: 20px;
        label {
          left: 0;  
          padding-left: 0px; 
        }
        input {
            margin-bottom: 10px;
        }
        .applyCoupon {
            text-align: center;
            color: #35b7fc;
            cursor: pointer;
            border: 1px solid #35b7fc;
            padding: 4px;
            border-radius: 6px;
            transition: 0.2s;
        }
        .applyCoupon:hover {
            background-color: #35b7fc;
            color: white;
        }
    }
}

@media only screen and (max-width: 576px) {
    .footer {
        justify-content: center !important;
    }
}