.resetPwd{
    margin: 80px;
    div{
        width: 400px;
    }
}

.centered {
    display: flex;
    justify-content: center;
}

.button {
    margin-bottom: 30px;
}