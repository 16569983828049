
.keycodeItem {   
        div {
            border-radius: 6px;
            transition: 0.2s;      
            margin-right: 5px;
            color: white;
            cursor: pointer;
        }
        div.delete {
            background-color: #db2828;
            border: 1px solid #db2828;
        }  
        div.delete:hover {
            background-color: white;
            color:#db2828;            
        }  
        div.edit {
            background-color: gold;
            border: 1px solid gold;
        }      
        div.edit:hover {
            background-color: white;
            color: gold;            
        }     
        div.manageDevice {
            text-align: center;
            color: #35b7fc;
            cursor: pointer;
            border: 1px solid #35b7fc;
            border-radius: 6px;
            transition: 0.2s;
        }
        div.manageDevice:hover {
            background-color: #35b7fc;
            color: white;
        }
        .modalContainer {
            display: none;
        }      
        .actions {
            min-width: 120px;
        }        
}

