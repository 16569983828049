.accountPage{
    //margin: 50px;
    padding-top: 15px;

    :global(.ui.tabular.menu a) {
        color: black !important;
    }
    
    :global(.ui.segment) {
        padding: 0px;
    }
    
    :global(.ui.bottom.attached.segment.tab) {
        padding-top: 25px;
    }
    
    :global(.input-field input) {
        width: 95%;
    }
    

    input {
        color: black !important;
        font-weight: 1 !important;
        text-transform: capitalize !important;
    }

    :global(.input-field label):not(:global(.active)) {
        text-transform: capitalize;
        font-weight: 1;
    }
}

.tabsContainer {
    margin: 0 0;
}   

.deleteContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 60px;
    * {
        align-self: center;
        text-align: center;        
        text-transform: none;
        margin-bottom: 40px;
    }
    h2 {
        color: black;        
    }

}

.content {
    margin-top: 30px;
}

.button {
    margin: 10px auto 10px auto;    
    width: 150px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
    line-height: 50px;
    font-weight: 500;
    color: #FFFFFF;
    font-family:Gotham-Medium;
    background-color: #35b7fc;
    border: #35b7fc solid 1px;        
    margin-bottom: 25px;
    transition: 0.2s;
    outline: none;
    padding: 0;
}

.button:hover {
    background-color: #FFFFFF;
    color: #35b7fc;
    border: #35b7fc solid 1px;        
}


.userEditInfo{        
    display: flex;
    justify-content: center;    
    padding: 30px 60px;
    div{
        flex: 1;
        max-width: 700px;
        span{
            display: none;
        }     
    }
}
.userInfo{    
    align-self: flex-start;
    div{
        span{
            display: block;
        }
        div{
            display: none;
        }
        .btn-save{
            display: none;
        }
    }
}

.resetContainer {
    display: flex;
    justify-content: center; 
    padding: 30px 60px;
    div {
        flex: 1;
        max-width: 700px;
    }
    
}

@media only screen and (min-width: 768px) {
    .accountPage {
        margin-right: 16.66666667%;
    }    
}

@media only screen and (max-width: 576px) {
    .tabsContainer {
        margin: 0 15px;
    }   
}