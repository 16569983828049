.sharedAccountsPage{
    padding-top: 30px;
    
    .sharedAccountsContainer {
        width: 100%;        
    }
    .successContainer {
        color: green;
        padding-left: 15px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .errorContainer {
        color: red;
        padding-left: 15px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .inviteContainer {
        display: flex;
    }

    .inviteContainer span {
        width: 33%;
    }

    .inviteContainer :global(.input-field) {
        flex-grow: 1;        
        margin-bottom: 25px;    
    }

    .inviteContainer :global(.input-field) input {
        margin-bottom: 0px;     
    }

    .errorField{
        input{
            border-bottom: 2px dashed #cf0000 !important;
            outline: none;
            box-shadow: none !important;
        }
        input:focus{
            border-bottom: 2px dashed #cf0000 !important;
            outline: none;
            box-shadow: none !important;
        }
    }

    .blueBtn{
        margin: 10px;
        width: 150px;
        height: 40px;
        cursor: pointer;
        text-align: center;
        border-radius: 6px;
        line-height: 40px;        
        color: #FFFFFF;
        font-family:Gotham-Medium;        
        background-color: #35b7fc;
        border: 1px solid #35b7fc;
        transition: 0.2s;
        margin-bottom: 0; 
        margin-left: 35px;
        margin-top: 15px;         
        font-size: 15px;      
    }
    
    .blueBtn:hover {
        color: #35b7fc;
        background-color: white;
    }
    
        
    :global {        
      li.active {
          background-color: #35b7fc;
      }
    }

    .sharedAccountsTable{
        
        margin-top: 20px;
        border-bottom: 2px solid rgba(171, 171, 171, 0.2);
        text-align: center;
        
        tr:nth-child(1) {            
            border-bottom: 2px solid rgba(171, 171, 171, 0.2);
            border-left: none;
            border-right: none;       
            font-size: larger;     
        }
        tr:nth-child(1):hover {
            background-color: white;
        }        

        th {
            border-top: none;
            border-right: none;
            border-left: none;
            width: 33%;
        }
        
        tr {
            border-left: 2px solid rgba(171, 171, 171, 0.2);
            border-right: 2px solid rgba(171, 171, 171, 0.2);
            text-align: center;
        }

        td {
            font-family: Gotham-Book;
            font-weight: 400;
            font-size: small;
            width: 33%;
            color: #404040;      
            text-align: center;          
        }                  
    }    
    .noItems {
        text-align: center;
        font-size: 21px;
        font-weight: bold;
        margin-top: 50px;
    }
}

.deleteModal {
    width: 50vw;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
    .title {
        align-self: center;
    }

    text {
        align-self: center;
        text-align: center;
    }

    .buttons {
        display: flex;
        align-self: center;

        .button {
            margin: 10px;
            width: 100px;
            height: 40px;
            cursor: pointer;
            text-align: center;
            border-radius: 6px;
            line-height: 37px;
            font-weight: 500;
            color: #FFFFFF;
            font-family: Gotham-Medium;
            background-color: #35b7fc;
            border: #35b7fc solid 1px;
            margin-bottom: 25px;
            transition: 0.2s;
            outline: none;
            padding: 0;
        }

        .button:hover {
            color: #35b7fc;
            background-color: white;
        }        

        .confirm {
            background-color: #db2828;
            border: #db2828 solid 1px;
        }

        .confirm:hover {
            background-color: white;
            color: #db2828;
        }


    }
}
