.activationBlock {
    max-width: 650px;       
    min-height: 98%;
    border-radius: 6px;
    border: 2px solid rgba(171, 171, 171, 0.2);
    background-color: #ffffff;
    box-shadow: 0 17px 38px 1px rgba(0, 0, 0, 0.05);
    padding: 0;
    padding-bottom: 20px;
    margin-top: 10px;
    >div {
        margin: 10px;
        margin-bottom: 5px;
    }
}

