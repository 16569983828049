.devicesPage{
  padding-top: 30px;
  width: 100%;
  min-width: 48vw;

  .devicesContainer {
      margin-left: 6%;
      margin-right: 6%;
      width: 100%;
      text-align: center;
  }
  
  .devicesPagination {
      justify-content: center;
      margin-top: 15px;
  }
  :global {
    li.active {
        background-color: #35b7fc;
    }
  }
  

  .devicesTable{
      
      border-bottom: 2px solid rgba(171, 171, 171, 0.2);
      text-align: center;
      
      tr:nth-child(1) {        
          border-left: none;
          border-right: none;       
          font-size: larger;     
      }
      tr:nth-child(1):hover {
          background-color: white;
      }        

      th {
          border-top: none;
          border-right: none;
          border-left: none;
          width: 15%;
      }
      
      tr {
          border-left: 2px solid rgba(171, 171, 171, 0.2);
          border-right: 2px solid rgba(171, 171, 171, 0.2);
          text-align: center;
      }

      td {
          font-family: Gotham-Book;
          font-weight: 400;
          font-size: small;
          width: 15%;
          color: #404040;      
          text-align: center;          
      }        

      td:nth-child(2), th:nth-child(2) {
          width: 25%
      }

      td:nth-child(5) {
          text-transform: capitalize;
      }
      
      td:last-child {
          display: flex;
          justify-content: center;
          width: 100%;
      }

      td:last-child div {
          flex-grow: .5;
      }
      
      .break {
          word-break: break-word;
      }
  }    
}
