.button {
    background-color: #039be5;
}

.flex {
    display: flex;
    justify-content: center;
}

.logInWrapper{    
    flex: 2;
    max-width: 400px;
    background-color: #FFFFFF;
    border: 5px solid #039be5;
    border-radius: 10px;        
    box-shadow: 30px 30px 30px #CDCDCD;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;    
    margin-top: 10px;
    text-align: center;
     
    .logo {
        background-color: #35b7fc;
        padding-top: 20px;
        padding-bottom: 20px;
        img {
            width: 84px;
        }
    }

    .button {        
        width: 150px;
        height: 50px;
        cursor: pointer;
        text-align: center;
        border-radius: 6px;
        line-height: 50px;
        font-weight: 500;
        color: #FFFFFF;
        font-family:Gotham-Medium;
        background-color: #35b7fc;
        border: #35b7fc solid 1px;                
        transition: 0.2s;
        outline: none;
        padding: 0;        
        padding-bottom: 10px;
        box-shadow: none;
        margin-left: auto;
        margin-right: auto;        
        display: block;
    }

    .button:hover {
        background-color: #FFFFFF;
        color: #35b7fc;
        border: #35b7fc solid 1px;        
    }     
    
    .center {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        display: block;
        width: 80%;        
    }

    .forgot {
        margin-top: 15px;
        color: #35b7fc;
        cursor: pointer;    
        display: block;    
    }

    .forgot:hover {
        color: #007bff;
    } 
    
    .loginInput {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 920px) {
    .logInWrapper {
        margin-left: 30px;
        margin-right: 30px;
    }
}