
.footer {
  display: flex;
  justify-content: flex-end;
  height: 100px;
  align-items: center;                
  span {
      cursor: pointer;
      color: #35b7fc;
  }
  button {
    margin: 10px;
    width: 150px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    border-radius: 6px !important;
    line-height: 50px;
    font-weight: 500;
    color: #FFFFFF;
    font-family:Gotham-Medium;
    background-color: #35b7fc !important;
    border: #35b7fc solid 1px !important;        
    margin-bottom: 25px;
    transition: 0.2s;
    outline: none;
    padding: 0 !important;        
    padding-bottom: 10px !important;
  }
  button:hover {
    background-color: #FFFFFF !important;
    color: #35b7fc !important;
    border: #35b7fc solid 1px !important;        
  }
}

.flexCenter {
  justify-content: center;
}