.container {
    margin-left:10px;
    margin-right: 10px;
    margin-top:15px;    

    p {
        margin-bottom: 0px;
        color: #9e9e9e;
        font-family: Gotham-Book;
        font-size: 12px;
    }

    .active {
        text-transform: uppercase;
        font-weight: 600;
        color: #35b7fc        
    }
    
    :global(.react-tel-input .form-control) {
        border-top:none;
        border-right: none;
        border-left: 0px;
        border-bottom: 1px solid #9e9e9e;
        border-radius: 0;
        width: calc(100% - 48px);
        padding-left:0px;
        margin-left:48px;
    }

    :global(.react-tel-input .flag-dropdown) {
        background: white;
        border: none;
    }

    :global(.react-tel-input .flag-dropdown:focus) {
        background: white;
        border: none;
    }
}


