.container {
    display: flex;
    justify-content: center;    
}

.inquires {
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid black;
    padding: 10px 30px;
    border-radius: 6px;
    font-weight: bold;
}