.activeLink{
    border-bottom: 2px solid #ffffff;
    color: #FFFFFF;
    padding-bottom: 5px;
    text-decoration: none;
}
header{
    background-color: #35b7fc;
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 100;
    font-weight: 400;
    ul{
        display: inline-block;
        line-height: 135px;
        li{
            text-transform: uppercase;
            display: none;
            font-size: 20px;
            letter-spacing: 2px;
            display: inline-block;
            // .activeLink{
            //     display: none;
            //     border-bottom: 2px solid #ffffff;
            //     color: #FFFFFF;
            //     padding-bottom: 5px;
            //     text-decoration: none;
            // }
            a{
                font-family:Gotham-Medium;
                font-weight: 700;
                text-decoration: none !important;
                color: #FFFFFF;
            }
            a:hover{
                color: #ffffff;
            }
            img{
                margin-right: 90px;
                padding-bottom: 25px;
                width: 135px;
            }
        }
    }
    .showMenu{
        display: none;
    }
    .currentUserBlock{
        display: inline-block;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        user-select: none;      
        font-family: GothamBook;
        .userInitials{
            font-family: Gotham-Book;
            font-weight: 400;
            color: #ffffff;
            font-size: 22px;
            border: 1px solid #FFFFFF;
            padding: 12px 10px 12px 10px;
            border-radius: 50px;
            text-transform: uppercase;
        }
        .userFullname{
            font-family: Gotham-Book;
            font-weight: 400;
            color: #ffffff;
            margin: 0 15px 0 15px;
            position: relative;
        }
        .dropMenu{
            width: 10px;
            height: 10px;
            border-right: 2px solid #FFFFFF;
            border-bottom: 2px solid #FFFFFF;
            display: inline-block;
            transform: rotate(45deg);
            cursor: pointer;
        }
    }
    .userMenu{
        width: 170px;
        height: 110px;
        background-color: #35b7fc;
        top: 135px;
        right:20px;
        position: absolute;
        z-index: 999;
        text-align: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        a{
            text-decoration: none;
        }
        div{
            color: #ffffff;
            margin-top: 5px;
            cursor: pointer;
        }
        div:hover{
            border-bottom: 2px solid #ffffff;
        }
    }
}

@media only screen and (min-width: 1920px) {
    header{
        height: 135px;
        padding-left: 70px;
        padding-right: 70px;
        ul{
            display: inline-block;
            line-height: 135px;
            li{
                margin-left: 30px;
                margin-right: 30px;
                img{
                    margin-right: 90px;
                    padding-bottom: 25px;
                    width: 135px;
                }
            }
        }
        .currentUserBlock{
            top: 50%;
            transform: translateY(-50%);
            right: 30px;
            .userInitials{
                font-size: 32px;
                padding: 14px 10px 14px 10px;
            }
            .userFullname{
                transform: translate(50%, 0);
                font-size: 22px;
            }
        }
    }
}
@media only screen and (min-width: 1601px) {
    header{
        height: 84px;
        padding-left: 40px;
        padding-right: 40px;
        ul{
            line-height: 84px;
            li{
                margin-left: 8px;
                margin-right: 8px;
                font-size: 14px;
                img{
                    margin-right: 90px;
                    padding-bottom: 16px;
                    width: 84px;
                }
            }
        }
        .currentUserBlock{
            top: 50%;
            transform: translateY(-50%);
        }
        .showMenu{
            display: none;
        }
        .userMenu{
            top: 84px;
        }
    }
}
@media only screen and (max-width: 1600px) {
    header{
        height: 84px;
        padding-left: 40px;
        padding-right: 40px;
        ul{
            line-height: 84px;
            li{
                margin-left: 8px;
                margin-right: 8px;
                font-size: 14px;
                img{
                    margin-right: 90px;
                    padding-bottom: 16px;
                    width: 84px;
                }
            }
        }
        .currentUserBlock{
            top: 50%;
            transform: translateY(-50%);
        }
        .showMenu{
            display: none;
        }
        .userMenu{
            top: 84px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    header{
        height: 45px;
        padding-left: 20px;
        padding-right: 40px;
        ul{
            line-height: 45px;
            li{
                margin-top: -7px;
                margin-left: 8px;
                margin-right: 8px;
                font-size: 12px;
                img{
                    margin-right: 30px;
                    margin-top: 8px;
                    width: 64px;
                }
            }
        }
        .showMenu{
            display: none;
        }
        .currentUserBlock{
            top: 50%;
            transform: translateY(-50%);
            right: 30px;
            user-select: none;      
            .userInitials{
                color: #ffffff;
                font-size: 16px;
                padding: 6px;
                border-radius: 100px;
                text-transform: uppercase;
                border: 1.5px solid #FFFFFF;
            }
            .userFullname{
                margin: 0 15px 0 15px;
            }
        }
        .userMenu{
            top: 45px;
        }
    }
    
}

@media only screen and (max-width: 950px) {
    header{
        ul{
            background-color: #35b7fc;
            display: none;
            position: absolute;
            top: 45px;
            left: 0;
            right: 0;
            li{
                display: block;
                width: 100%;
                text-align: center;
                border-bottom: 1px solid #ffffff;
                img{
                    display: none;
                }
            }
        }
        .showMenu{
            position: absolute;
            top: 10px;
            left: 40px;
            cursor: pointer;
            display: inline-block;
            width: 25px;
            height: 25px;
            border-top: 2px solid #ffffff;
            border-bottom: 2px solid #ffffff;
        }
        .showMenu:after{
            position: absolute;
            content: '';
            top: 10px;
            width: 25px;
            height: 25px;

            border-top: 2px solid #ffffff;
        }
        .hideMenu{
            position: absolute;
            top: 15px;
            left: 40px;
            cursor: pointer;
            display: inline-block;
            width: 25px;
            height: 25px;
            transform: rotate(45deg);
            border-top: 3px solid #ffffff;
        }
        .hideMenu:after{
            content: '';
            position: absolute;
            top: -14px;
            left: 10px;
            width: 25px;
            height: 25px;
            border-top: 3px solid #ffffff;
            transform: rotate(-92deg);
        }
        .userMenu{
            top:45px;
        }
    }
}