.terms {
    padding: 20px;

    h2 {
        text-align: center;
        font-size: 19px;    
        margin-top: 10px;
        margin-bottom: 25px;
    }

    h3 {
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 13px;
    }

    p {
        text-align: justify;
        font-size: 13px;
    }

    ol {
        margin-top: 10px;
        margin-bottom: 10px;

        ol {
            list-style: lower-alpha;
        }
    }
}



