.userItem {   
    div {
        border-radius: 6px;
        transition: 0.2s;      
        margin-right: 5px;
        color: white;
        cursor: pointer;
        width: 45px;
    }
    div.delete {
        background-color: #db2828;
        border: 1px solid #db2828;
    }  
    div.delete:hover {
        background-color: white;
        color:#db2828;            
    }  
    div.edit {
        background-color: gold;
        border: 1px solid gold;
    }      
    div.edit:hover {
        background-color: white;
        color: gold;            
    } 
    div.reset {
        background-color: #17a2b8;
        border: 1px solid #17a2b8;
    }      
    div.reset:hover {
        background-color: white;
        color: #17a2b8;            
    }   
    .modalContainer {
        display: none;
    }   
}

